export const userTypes = [
  {
    key: 'customer',
    label: 'Customer',
  },
  {
    key: 'houseBuilder',
    label: 'House Builder',
  },
  {
    key: 'architect',
    label: 'Architect',
  },
];

export const buildingSystemOptions = [
  {
    key: 'SIPS',
    label: 'Structured Insulated Panels (SIPS)',
  },
  {
    key: 'closedPanelSystem',
    label: 'Closed panel system',
  },
  {
    key: 'fusionLog',
    label: 'Fusion Log',
  },
  {
    key: 'LGS',
    label: 'Light Gauge Steel Structure (LGS)',
  },
  {
    key: 'timberFrame',
    label: 'Timber Frame',
  },
  {
    key: 'volumetric',
    label: 'Volumetric',
  },
  {
    key: 'other',
    label: 'Other',
  },
];

export const companyAccrediationsOptions = [
  {
    key: 'bopas',
    label: 'BOPAS',
  },
  {
    key: 'nacsba',
    label: 'NaCSBA',
  },
  {
    key: 'structuralTimberAssociation',
    label: 'Structural Timber Association',
  },
  {
    key: 'passivhausInstitut',
    label: 'Passivhaus Institute',
  },
  {
    key: 'bmTrada',
    label: 'BM Trada',
  },
  {
    key: 'riba',
    label: 'RIBA',
  },
  {
    key: 'other',
    label: 'Other',
  },
];

export const structuralWarrantyProviderOptions = [
  {
    key: 'buildStore',
    label: 'Build store',
  },
  {
    key: 'NHBC',
    label: 'NHBC',
  },
  {
    key: 'LABC',
    label: 'LABC',
  },
  {
    key: 'ICW',
    label: 'ICW',
  },
  {
    key: 'ABC',
    label: 'ABC',
  },
  {
    key: 'premier',
    label: 'Premier',
  },
  {
    key: 'protek',
    label: 'Protek',
  },
  {
    key: 'checkmate',
    label: 'Checkmate',
  },
  {
    key: 'blp',
    label: 'BLP',
  },
  {
    key: 'advantage',
    label: 'Advantage',
  },
  {
    key: 'buildsafe',
    label: 'Buildsafe',
  },
  {
    key: 'other',
    label: 'Other',
  },
];

export const yesNoOptions = [
  {
    key: 'yes',
    label: 'Yes',
  },
  {
    key: 'no',
    label: 'No',
  },
];

export const inhouseConsultantsOptions = [
  {
    key: 'inhouse',
    label: 'Inhouse',
  },
  {
    key: '3rdParty',
    label: '3rd party',
  },
];

export const houseFacadeOptions = [
  {
    key: 'fo',
    label: 'House envelope',
  },
  {
    key: 'brick',
    label: 'Brick (red)',
  },
  {
    key: 'render',
    label: 'Render',
  },
  {
    key: 'timber',
    label: 'Timber',
  },
];

export const energySolutionsOptions = [
  {
    key: 'ASHP',
    label: 'Air Source Heat Pumps (ASHP)',
  },
  {
    key: 'GSHP',
    label: 'Ground Source Heat Pumps (GSHP)',
  },
  {
    key: 'MVHR',
    label: 'Mechanical ventilation units (MVHR)',
  },
  {
    key: 'solarRoofPanels',
    label: 'Solar Roof panels',
  },
  {
    key: 'extraInsulations',
    label: 'Extra insulations',
  },
  {
    key: 'homeAutomation',
    label: 'Home Automation',
  },
  {
    key: 'others',
    label: 'Others',
  },
];

export const sustainabilityRequirementsOptions = [
  {
    key: 'ASHP',
    label: 'Air Source Heat Pumps (ASHP)',
  },
  {
    key: 'GSHP',
    label: 'Ground Source Heat Pumps (GSHP)',
  },
  {
    key: 'MVHR',
    label: 'Mechanical Ventilation Heat Recovery (MVHR)',
  },
  {
    key: 'solarRoofPanels',
    label: 'Solar Roof Panels',
  },
];

export const programmeDurationOptions = [
  {
    key: '3m',
    label: '3 months',
  },
  {
    key: '6m',
    label: '6 months',
  },
  {
    key: '9m',
    label: '9 months',
  },
  {
    key: '12m',
    label: '12 months',
  },
];

export const geographycalReachOptions = [
  {
    key: 'wales',
    label: 'Wales',
  },
  {
    key: 'scotland',
    label: 'Scotland',
  },
  {
    key: 'london',
    label: 'London',
  },
  {
    key: 'northEast',
    label: 'North East',
  },
  {
    key: 'northWest',
    label: 'North West',
  },
  {
    key: 'yorkshire',
    label: 'Yorkshire',
  },
  {
    key: 'eastMidlands',
    label: 'East Midlands',
  },
  {
    key: 'westMidlands',
    label: 'West Midlands',
  },
  {
    key: 'southEast',
    label: 'South East',
  },
  {
    key: 'eastOfEngland',
    label: 'East of England',
  },
  {
    key: 'southWest',
    label: 'South West',
  },
];

export const siteGradientOptions = [
  {
    key: 'nearlyLevel',
    label: 'Nearly level (0 to 5°)',
  },
  {
    key: 'gentlySloping',
    label: 'Gently sloping (5° to 10°)',
  },
  {
    key: 'stronglySloping',
    label: 'Strongly sloping (10° to 15°)',
  },
  {
    key: 'moderatelySteep',
    label: 'Moderately steep (15° to 25°)',
  },
  {
    key: 'steep',
    label: 'Steep (25° to 35°)',
  },
  {
    key: 'verySteep',
    label: 'Very steep (Over 35°)',
  },
];

export const planningPermissionOptions = [
  {
    key: 'fullPermission',
    label: 'Yes Full planning permission',
  },
  {
    key: 'outlinePermission',
    label: 'Yes Outline planning permission',
  },
  {
    key: 'notApplied',
    label: 'Not applied yet',
  },
];

export const serviceOptions = [
  {
    key: 'architect',
    label: 'Architect',
  },
  {
    key: 'planningConsultancy',
    label: 'Planning consultancy',
  },
  {
    key: 'projectManagement',
    label: 'Project Management',
  },
  {
    key: 'groundworksLandscaping',
    label: 'Groundworks & Landscaping',
  },
  {
    key: 'other',
    label: 'Other',
  },
];

export const designScopeOptions = [
  {
    key: 'planningDesign',
    label: 'Planning design',
  },
  {
    key: 'fullBuildingRegsDesign',
    label: 'Full building regs design',
  },
  {
    key: 'landscapingLayoutDesign',
    label: 'Landscaping layout design',
  },
  {
    key: 'landscapingSpecifying',
    label: 'Landscaping specifying',
  },
  {
    key: 'other',
    label: 'Other',
  },
];

export const houseDeliveryOptions = [
  {
    key: 'frameOnly',
    label: 'House envelope',
  },
  {
    key: 'fullHouseBuild',
    label: 'Full house build',
  },
];

export const houseCompletionOptions = [
  {
    key: 'frameOnly',
    label: 'House envelope (up to water tight & including timber staircases)',
  },
  {
    key: 'wholeHouseUpToOneCoat',
    label: 'Whole house complete up to 1 coat of paint',
  },
  {
    key: 'houseCompleteWithBathrooms',
    label: 'House complete include bathrooms',
  },
  {
    key: 'houseCompleteWithBathroomsAndKitchens',
    label: 'House complete including bathrooms and kitchens',
  },
  {
    key: 'houseCompleteTurnkey',
    label: 'House complete (turnkey) including groundworks and external cladding',
  },
];

export const plotStageOptions = [
  {
    key: 'plotSecured',
    label: 'Plot secured',
  },
  {
    key: 'plotSearchesInProgress',
    label: 'Plot searches in progress',
  },
  {
    key: 'plotAvailabilityConfirmed',
    label: 'Plot availability confirmed',
  },
  {
    key: 'gardenPlot',
    label: 'Garden plot',
  },
];

export const siteHistoryOptions = [
  {
    key: 'brownfield',
    label: 'Brownfield (previously built on)',
  },
  {
    key: 'greenfield',
    label: 'Greenfield (not previously built on)',
  },
  {
    key: 'others',
    label: 'Others (mining, landfill, etc.)',
  },
];

export const localPlanningAuthorityOptions = [
  { key: 'aberdeenCity', label: 'Aberdeen City' },
  { key: 'aberdeenshire', label: 'Aberdeenshire' },
  { key: 'adur', label: 'Adur' },
  { key: 'allerdale', label: 'Allerdale' },
  { key: 'amberValley', label: 'Amber Valley' },
  { key: 'angus', label: 'Angus' },
  { key: 'antrimAndNewtownabbey', label: 'Antrim and Newtownabbey' },
  { key: 'ardsAndNorthDown', label: 'Ards and North Down' },
  { key: 'argyllAndBute', label: 'Argyll and Bute' },
  { key: 'armaghCityBanbridgeAndCraigavon', label: 'Armagh City, Banbridge and Craigavon' },
  { key: 'arun', label: 'Arun' },
  { key: 'ashfield', label: 'Ashfield' },
  { key: 'ashford', label: 'Ashford' },
  { key: 'aylesburyVale', label: 'Aylesbury Vale' },
  { key: 'babergh', label: 'Babergh' },
  { key: 'barkingAndDagenham', label: 'Barking and Dagenham' },
  { key: 'barnet', label: 'Barnet' },
  { key: 'barnsley', label: 'Barnsley' },
  { key: 'barrowInFurness', label: 'Barrow-in-Furness' },
  { key: 'basildon', label: 'Basildon' },
  { key: 'basingstokeAndDeane', label: 'Basingstoke and Deane' },
  { key: 'bassetlaw', label: 'Bassetlaw' },
  { key: 'bathAndNorthEastSomerset', label: 'Bath and North East Somerset' },
  { key: 'bedford', label: 'Bedford' },
  { key: 'belfast', label: 'Belfast' },
  { key: 'bexley', label: 'Bexley' },
  { key: 'birmingham', label: 'Birmingham' },
  { key: 'blaby', label: 'Blaby' },
  { key: 'blackburnWithDarwen', label: 'Blackburn with Darwen' },
  { key: 'blackpool', label: 'Blackpool' },
  { key: 'blaenauGwent', label: 'Blaenau Gwent' },
  { key: 'bolsover', label: 'Bolsover' },
  { key: 'bolton', label: 'Bolton' },
  { key: 'boston', label: 'Boston' },
  { key: 'bournemouth', label: 'Bournemouth' },
  { key: 'bracknellForest', label: 'Bracknell Forest' },
  { key: 'bradford', label: 'Bradford' },
  { key: 'braintree', label: 'Braintree' },
  { key: 'breckland', label: 'Breckland' },
  { key: 'breconBeaconsNationalParkAuthority', label: 'Brecon Beacons National Park Authority' },
  { key: 'brent', label: 'Brent' },
  { key: 'brentwood', label: 'Brentwood' },
  { key: 'bridgend', label: 'Bridgend' },
  { key: 'brightonAndHove', label: 'Brighton and Hove' },
  { key: 'bristol', label: 'Bristol' },
  { key: 'broadland', label: 'Broadland' },
  { key: 'broads', label: 'Broads' },
  { key: 'bromley', label: 'Bromley' },
  { key: 'bromsgrove', label: 'Bromsgrove' },
  { key: 'broxbourne', label: 'Broxbourne' },
  { key: 'broxtowe', label: 'Broxtowe' },
  { key: 'buckinghamshire', label: 'Buckinghamshire' },
  { key: 'burnley', label: 'Burnley' },
  { key: 'bury', label: 'Bury' },
  { key: 'caerphilly', label: 'Caerphilly' },
  { key: 'cairngorms', label: 'Cairngorms' },
  { key: 'calderdale', label: 'Calderdale' },
  { key: 'cambridge', label: 'Cambridge' },
  { key: 'cambridgeshire', label: 'Cambridgeshire' },
  { key: 'camden', label: 'Camden' },
  { key: 'cannockChase', label: 'Cannock Chase' },
  { key: 'canterbury', label: 'Canterbury' },
  { key: 'cardiff', label: 'Cardiff' },
  { key: 'carlisle', label: 'Carlisle' },
  { key: 'carmarthenshire', label: 'Carmarthenshire' },
  { key: 'castlePoint', label: 'Castle Point' },
  { key: 'causewayCoastAndGlens', label: 'Causeway Coast and Glens' },
  { key: 'centralBedfordshire', label: 'Central Bedfordshire' },
  { key: 'ceredigion', label: 'Ceredigion' },
  { key: 'charnwood', label: 'Charnwood' },
  { key: 'chelmsford', label: 'Chelmsford' },
  { key: 'cheltenham', label: 'Cheltenham' },
  { key: 'cherwell', label: 'Cherwell' },
  { key: 'cheshireEast', label: 'Cheshire East' },
  { key: 'cheshireWestAndChester', label: 'Cheshire West and Chester' },
  { key: 'chesterfield', label: 'Chesterfield' },
  { key: 'chichester', label: 'Chichester' },
  { key: 'chiltern', label: 'Chiltern' },
  { key: 'chorley', label: 'Chorley' },
  { key: 'christchurch', label: 'Christchurch' },
  { key: 'cityOfLondon', label: 'City of London' },
  { key: 'clackmannanshire', label: 'Clackmannanshire' },
  { key: 'colchester', label: 'Colchester' },
  { key: 'comhairleNanEileanSiar', label: 'Comhairle nan Eilean Siar (Western Isles)' },
  { key: 'conwy', label: 'Conwy' },
  { key: 'copeland', label: 'Copeland' },
  { key: 'corby', label: 'Corby' },
  { key: 'cornwall', label: 'Cornwall' },
  { key: 'cotswold', label: 'Cotswold' },
  { key: 'coventry', label: 'Coventry' },
  { key: 'craven', label: 'Craven' },
  { key: 'crawley', label: 'Crawley' },
  { key: 'croydon', label: 'Croydon' },
  { key: 'cumbria', label: 'Cumbria' },
  { key: 'dacorum', label: 'Dacorum' },
  { key: 'darlington', label: 'Darlington' },
  { key: 'dartford', label: 'Dartford' },
  { key: 'dartmoorNationalParkAuthority', label: 'Dartmoor National Park Authority' },
  { key: 'daventry', label: 'Daventry' },
  { key: 'denbighshire', label: 'Denbighshire' },
  { key: 'derby', label: 'Derby' },
  { key: 'derbyshire', label: 'Derbyshire' },
  { key: 'derbyshireDales', label: 'Derbyshire Dales' },
  { key: 'derryCityAndStrabane', label: 'Derry City and Strabane' },
  { key: 'devon', label: 'Devon' },
  { key: 'doncaster', label: 'Doncaster' },
  { key: 'dorset', label: 'Dorset' },
  { key: 'dover', label: 'Dover' },
  { key: 'dudley', label: 'Dudley' },
  { key: 'dumfriesAndGalloway', label: 'Dumfries and Galloway' },
  { key: 'dundeeCity', label: 'Dundee City' },
  { key: 'durham', label: 'Durham' },
  { key: 'ealing', label: 'Ealing' },
  { key: 'eastAyrshire', label: 'East Ayrshire' },
  { key: 'eastCambridgeshire', label: 'East Cambridgeshire' },
  { key: 'eastDevon', label: 'East Devon' },
  { key: 'eastDorset', label: 'East Dorset' },
  { key: 'eastDunbartonshire', label: 'East Dunbartonshire' },
  { key: 'eastHampshire', label: 'East Hampshire' },
  { key: 'eastHertfordshire', label: 'East Hertfordshire' },
  { key: 'eastLindsey', label: 'East Lindsey' },
  { key: 'eastLothian', label: 'East Lothian' },
  { key: 'eastNorthamptonshire', label: 'East Northamptonshire' },
  { key: 'eastRenfrewshire', label: 'East Renfrewshire' },
  { key: 'eastRidingOfYorkshire', label: 'East Riding of Yorkshire' },
  { key: 'eastStaffordshire', label: 'East Staffordshire' },
  { key: 'eastSussex', label: 'East Sussex' },
  { key: 'eastbourne', label: 'Eastbourne' },
  { key: 'eastleigh', label: 'Eastleigh' },
  { key: 'ebbsfleetDevelopmentCorporation', label: 'Ebbsfleet Development Corporation' },
  { key: 'eden', label: 'Eden' },
  { key: 'edinburgh', label: 'Edinburgh' },
  { key: 'elmbridge', label: 'Elmbridge' },
  { key: 'enfield', label: 'Enfield' },
  { key: 'eppingForest', label: 'Epping Forest' },
  { key: 'epsomAndEwell', label: 'Epsom and Ewell' },
  { key: 'erewash', label: 'Erewash' },
  { key: 'essex', label: 'Essex' },
  { key: 'exeter', label: 'Exeter' },
  { key: 'exmoorNationalPark', label: 'Exmoor National Park' },
  { key: 'falkirk', label: 'Falkirk' },
  { key: 'fareham', label: 'Fareham' },
  { key: 'fenland', label: 'Fenland' },
  { key: 'fermanaghAndOmagh', label: 'Fermanagh and Omagh' },
  { key: 'fife', label: 'Fife' },
  { key: 'flintshire', label: 'Flintshire' },
  { key: 'folkestoneAndHythe', label: 'Folkestone and Hythe' },
  { key: 'forestHeath', label: 'Forest Heath' },
  { key: 'forestOfDean', label: 'Forest of Dean' },
  { key: 'fylde', label: 'Fylde' },
  { key: 'gateshead', label: 'Gateshead' },
  { key: 'gedling', label: 'Gedling' },
  { key: 'glasgowCity', label: 'Glasgow City' },
  { key: 'gloucester', label: 'Gloucester' },
  { key: 'gloucestershire', label: 'Gloucestershire' },
  { key: 'gosport', label: 'Gosport' },
  { key: 'gravesham', label: 'Gravesham' },
  { key: 'greatYarmouth', label: 'Great Yarmouth' },
  { key: 'greenwich', label: 'Greenwich' },
  { key: 'guildford', label: 'Guildford' },
  { key: 'gwynedd', label: 'Gwynedd' },
  { key: 'hackney', label: 'Hackney' },
  { key: 'halton', label: 'Halton' },
  { key: 'hambleton', label: 'Hambleton' },
  { key: 'hammersmithAndFulham', label: 'Hammersmith and Fulham' },
  { key: 'hampshire', label: 'Hampshire' },
  { key: 'harborough', label: 'Harborough' },
  { key: 'haringey', label: 'Haringey' },
  { key: 'harlow', label: 'Harlow' },
  { key: 'harrogate', label: 'Harrogate' },
  { key: 'harrow', label: 'Harrow' },
  { key: 'hart', label: 'Hart' },
  { key: 'hartlepool', label: 'Hartlepool' },
  { key: 'hastings', label: 'Hastings' },
  { key: 'havant', label: 'Havant' },
  { key: 'havering', label: 'Havering' },
  { key: 'herefordshire', label: 'Herefordshire' },
  { key: 'hertfordshire', label: 'Hertfordshire' },
  { key: 'hertsmere', label: 'Hertsmere' },
  { key: 'highPeak', label: 'High Peak' },
  { key: 'highland', label: 'Highland' },
  { key: 'hillingdon', label: 'Hillingdon' },
  { key: 'hinckleyAndBosworth', label: 'Hinckley and Bosworth' },
  { key: 'horsham', label: 'Horsham' },
  { key: 'hounslow', label: 'Hounslow' },
  { key: 'hull', label: 'Hull' },
  { key: 'huntingdonshire', label: 'Huntingdonshire' },
  { key: 'hyndburn', label: 'Hyndburn' },
  { key: 'inverclyde', label: 'Inverclyde' },
  { key: 'ipswich', label: 'Ipswich' },
  { key: 'isleOfAnglesey', label: 'Isle of Anglesey' },
  { key: 'isleOfWight', label: 'Isle of Wight' },
  { key: 'islington', label: 'Islington' },
  { key: 'kensingtonAndChelsea', label: 'Kensington and Chelsea' },
  { key: 'kent', label: 'Kent' },
  { key: 'kettering', label: 'Kettering' },
  { key: 'king’sLynnAndWestNorfolk', label: 'King’s Lynn and West Norfolk' },
  { key: 'kingstonUponThames', label: 'Kingston upon Thames' },
  { key: 'kirklees', label: 'Kirklees' },
  { key: 'knowsley', label: 'Knowsley' },
  { key: 'lakeDistrictNationalPark', label: 'Lake District National Park' },
  { key: 'lambeth', label: 'Lambeth' },
  { key: 'lancashire', label: 'Lancashire' },
  { key: 'lancaster', label: 'Lancaster' },
  { key: 'leeds', label: 'Leeds' },
  { key: 'leicester', label: 'Leicester' },
  { key: 'leicestershire', label: 'Leicestershire' },
  { key: 'lewes', label: 'Lewes' },
  { key: 'lewisham', label: 'Lewisham' },
  { key: 'lichfield', label: 'Lichfield' },
  { key: 'lincoln', label: 'Lincoln' },
  { key: 'lincolnshire', label: 'Lincolnshire' },
  { key: 'lisburnAndCastlereagh', label: 'Lisburn and Castlereagh' },
  { key: 'liverpool', label: 'Liverpool' },
  { key: 'lochLomondAndTheTrossachs', label: 'Loch Lomond and the Trossachs' },
  { key: 'londonLegacyDevelopmentCorporation', label: 'London Legacy Development Corporation' },
  { key: 'lpa', label: 'LPA' },
  { key: 'luton', label: 'Luton' },
  { key: 'maidstone', label: 'Maidstone' },
  { key: 'maldon', label: 'Maldon' },
  { key: 'malvernHills', label: 'Malvern Hills' },
  { key: 'manchester', label: 'Manchester' },
  { key: 'mansfield', label: 'Mansfield' },
  { key: 'medway', label: 'Medway' },
  { key: 'melton', label: 'Melton' },
  { key: 'mendip', label: 'Mendip' },
  { key: 'merthyrTydfil', label: 'Merthyr Tydfil' },
  { key: 'merton', label: 'Merton' },
  { key: 'midAndEastAntrim', label: 'Mid and East Antrim' },
  { key: 'midDevon', label: 'Mid Devon' },
  { key: 'midSuffolk', label: 'Mid Suffolk' },
  { key: 'midSussex', label: 'Mid Sussex' },
  { key: 'midUlster', label: 'Mid Ulster' },
  { key: 'middlesbrough', label: 'Middlesbrough' },
  { key: 'midlothian', label: 'Midlothian' },
  { key: 'miltonKeynes', label: 'Milton Keynes' },
  { key: 'moleValley', label: 'Mole Valley' },
  { key: 'monmouthshire', label: 'Monmouthshire' },
  { key: 'moray', label: 'Moray' },
  { key: 'neathPortTalbot', label: 'Neath Port Talbot' },
  { key: 'newForest', label: 'New Forest' },
  { key: 'newForestNationalParkAuthority', label: 'New Forest National Park Authority' },
  { key: 'newarkAndSherwood', label: 'Newark and Sherwood' },
  { key: 'newcastle', label: 'Newcastle' },
  { key: 'newcastleUnderLyme', label: 'Newcastle under Lyme' },
  { key: 'newham', label: 'Newham' },
  { key: 'newport', label: 'Newport' },
  { key: 'newryMourneAndDown', label: 'Newry, Mourne and Down' },
  { key: 'norfolk', label: 'Norfolk' },
  { key: 'northAyrshire', label: 'North Ayrshire' },
  { key: 'northDevon', label: 'North Devon' },
  { key: 'northDorset', label: 'North Dorset' },
  { key: 'northEastDerbyshire', label: 'North East Derbyshire' },
  { key: 'northEastLincolnshire', label: 'North East Lincolnshire' },
  { key: 'northHertfordshire', label: 'North Hertfordshire' },
  { key: 'northKesteven', label: 'North Kesteven' },
  { key: 'northLanarkshire', label: 'North Lanarkshire' },
  { key: 'northLincolnshire', label: 'North Lincolnshire' },
  { key: 'northNorfolk', label: 'North Norfolk' },
  { key: 'northSomerset', label: 'North Somerset' },
  { key: 'northTyneside', label: 'North Tyneside' },
  { key: 'northWarwickshire', label: 'North Warwickshire' },
  { key: 'northWestLeicestershire', label: 'North West Leicestershire' },
  { key: 'northYorkMoorsNationalPark', label: 'North York Moors National Park' },
  { key: 'northYorkshire', label: 'North Yorkshire' },
  { key: 'northampton', label: 'Northampton' },
  { key: 'northamptonshire', label: 'Northamptonshire' },
  { key: 'northumberland', label: 'Northumberland' },
  { key: 'northumberlandNationalParkAuthority', label: 'Northumberland National Park Authority' },
  { key: 'norwich', label: 'Norwich' },
  { key: 'nottingham', label: 'Nottingham' },
  { key: 'nottinghamshire', label: 'Nottinghamshire' },
  { key: 'nuneatonAndBedworth', label: 'Nuneaton and Bedworth' },
  { key: 'oadbyAndWigston', label: 'Oadby and Wigston' },
  {
    key: 'oldOakAndParkRoyalDevelopmentCorporation',
    label: 'Old Oak and Park Royal Development Corporation',
  },
  { key: 'oldham', label: 'Oldham' },
  { key: 'orkneyIslands', label: 'Orkney Islands' },
  { key: 'oxford', label: 'Oxford' },
  { key: 'oxfordshire', label: 'Oxfordshire' },
  { key: 'peakDistrictNationalParkAuthority', label: 'Peak District National Park Authority' },
  { key: 'pembrokeshire', label: 'Pembrokeshire' },
  {
    key: 'pembrokeshireCoastNationalParkAuthority',
    label: 'Pembrokeshire Coast National Park Authority',
  },
  { key: 'pendle', label: 'Pendle' },
  { key: 'perthAndKinross', label: 'Perth and Kinross' },
  { key: 'peterborough', label: 'Peterborough' },
  { key: 'plymouth', label: 'Plymouth' },
  { key: 'poole', label: 'Poole' },
  { key: 'portsmouth', label: 'Portsmouth' },
  { key: 'powys', label: 'Powys' },
  { key: 'preston', label: 'Preston' },
  { key: 'purbeck', label: 'Purbeck' },
  { key: 'reading', label: 'Reading' },
  { key: 'redbridge', label: 'Redbridge' },
  { key: 'redcarAndCleveland', label: 'Redcar and Cleveland' },
  { key: 'redditch', label: 'Redditch' },
  { key: 'reigateAndBanstead', label: 'Reigate and Banstead' },
  { key: 'renfrewshire', label: 'Renfrewshire' },
  { key: 'rhonddaCynonTaff', label: 'Rhondda Cynon Taff' },
  { key: 'ribbleValley', label: 'Ribble Valley' },
  { key: 'richmondUponThames', label: 'Richmond upon Thames' },
  { key: 'richmondshire', label: 'Richmondshire' },
  { key: 'rochdale', label: 'Rochdale' },
  { key: 'rochford', label: 'Rochford' },
  { key: 'rossendale', label: 'Rossendale' },
  { key: 'rother', label: 'Rother' },
  { key: 'rotherham', label: 'Rotherham' },
  { key: 'rugby', label: 'Rugby' },
  { key: 'runnymede', label: 'Runnymede' },
  { key: 'rushcliffe', label: 'Rushcliffe' },
  { key: 'rushmoor', label: 'Rushmoor' },
  { key: 'rutland', label: 'Rutland' },
  { key: 'ryedale', label: 'Ryedale' },
  { key: 'salford', label: 'Salford' },
  { key: 'sandwell', label: 'Sandwell' },
  { key: 'scarborough', label: 'Scarborough' },
  { key: 'scottishBorders', label: 'Scottish Borders' },
  { key: 'sedgemoor', label: 'Sedgemoor' },
  { key: 'sefton', label: 'Sefton' },
  { key: 'selby', label: 'Selby' },
  { key: 'sevenoaks', label: 'Sevenoaks' },
  { key: 'sheffield', label: 'Sheffield' },
  { key: 'shetlandIslands', label: 'Shetland Islands' },
  { key: 'shropshire', label: 'Shropshire' },
  { key: 'slough', label: 'Slough' },
  { key: 'snowdoniaNationalParkAuthority', label: 'Snowdonia National Park Authority' },
  { key: 'solihull', label: 'Solihull' },
  { key: 'somerset', label: 'Somerset' },
  { key: 'southAyrshire', label: 'South Ayrshire' },
  { key: 'southBucks', label: 'South Bucks' },
  { key: 'southCambridgeshire', label: 'South Cambridgeshire' },
  { key: 'southDerbyshire', label: 'South Derbyshire' },
  { key: 'southDownsNationalParkAuthority', label: 'South Downs National Park Authority' },
  { key: 'southGlos', label: 'South Glos' },
  { key: 'southHams', label: 'South Hams' },
  { key: 'southHolland', label: 'South Holland' },
  { key: 'southKesteven', label: 'South Kesteven' },
  { key: 'southLakeland', label: 'South Lakeland' },
  { key: 'southLanarkshire', label: 'South Lanarkshire' },
  { key: 'southNorfolk', label: 'South Norfolk' },
  { key: 'southNorthamptonshire', label: 'South Northamptonshire' },
  { key: 'southOxfordshire', label: 'South Oxfordshire' },
  { key: 'southRibble', label: 'South Ribble' },
  { key: 'southSomerset', label: 'South Somerset' },
  { key: 'southStaffordshire', label: 'South Staffordshire' },
  { key: 'southTyneside', label: 'South Tyneside' },
  { key: 'southampton', label: 'Southampton' },
  { key: 'southendOnSea', label: 'Southend-on-Sea' },
  { key: 'southwark', label: 'Southwark' },
  { key: 'spelthorne', label: 'Spelthorne' },
  { key: 'stAlbans', label: 'St Albans' },
  { key: 'stEdmundsburyWestSuffolk', label: 'St Edmundsbury (West Suffolk)' },
  { key: 'stHelens', label: 'St Helens' },
  { key: 'stafford', label: 'Stafford' },
  { key: 'staffordshire', label: 'Staffordshire' },
  { key: 'staffordshireMoorlands', label: 'Staffordshire Moorlands' },
  { key: 'stevenage', label: 'Stevenage' },
  { key: 'stirling', label: 'Stirling' },
  { key: 'stockport', label: 'Stockport' },
  { key: 'stocktonOnTees', label: 'Stockton-on-Tees' },
  { key: 'stokeOnTrent', label: 'Stoke-on-Trent' },
  { key: 'stratfordOnAvon', label: 'Stratford on Avon' },
  { key: 'stroud', label: 'Stroud' },
  { key: 'suffolk', label: 'Suffolk' },
  { key: 'suffolkCoastal', label: 'Suffolk Coastal' },
  { key: 'sunderland', label: 'Sunderland' },
  { key: 'surrey', label: 'Surrey' },
  { key: 'surreyHeath', label: 'Surrey Heath' },
  { key: 'sutton', label: 'Sutton' },
  { key: 'swale', label: 'Swale' },
  { key: 'swansea', label: 'Swansea' },
  { key: 'swindon', label: 'Swindon' },
  { key: 'tameside', label: 'Tameside' },
  { key: 'tamworth', label: 'Tamworth' },
  { key: 'tandridge', label: 'Tandridge' },
  { key: 'tauntonDeane', label: 'Taunton Deane' },
  { key: 'teignbridge', label: 'Teignbridge' },
  { key: 'telfordAndWrekin', label: 'Telford and Wrekin' },
  { key: 'tendring', label: 'Tendring' },
  { key: 'testValley', label: 'Test Valley' },
  { key: 'tewkesbury', label: 'Tewkesbury' },
  { key: 'thanet', label: 'Thanet' },
  { key: 'theIslesOfScilly', label: 'The Isles of Scilly' },
  { key: 'threeRivers', label: 'Three Rivers' },
  { key: 'thurrock', label: 'Thurrock' },
  { key: 'tonbridgeAndMalling', label: 'Tonbridge and Malling' },
  { key: 'torbay', label: 'Torbay' },
  { key: 'torfaen', label: 'Torfaen' },
  { key: 'torridge', label: 'Torridge' },
  { key: 'towerHamlets', label: 'Tower Hamlets' },
  { key: 'trafford', label: 'Trafford' },
  { key: 'tunbridgeWells', label: 'Tunbridge Wells' },
  { key: 'uttlesford', label: 'Uttlesford' },
  { key: 'valeOfGlamorgan', label: 'Vale of Glamorgan' },
  { key: 'valeOfWhiteHorse', label: 'Vale of White Horse' },
  { key: 'wakefield', label: 'Wakefield' },
  { key: 'walsall', label: 'Walsall' },
  { key: 'walthamForest', label: 'Waltham Forest' },
  { key: 'wandsworth', label: 'Wandsworth' },
  { key: 'warrington', label: 'Warrington' },
  { key: 'warwick', label: 'Warwick' },
  { key: 'warwickshire', label: 'Warwickshire' },
  { key: 'watford', label: 'Watford' },
  { key: 'waveney', label: 'Waveney' },
  { key: 'waverley', label: 'Waverley' },
  { key: 'wealden', label: 'Wealden' },
  { key: 'wellingborough', label: 'Wellingborough' },
  { key: 'welwynHatfield', label: 'Welwyn Hatfield' },
  { key: 'westBerkshire', label: 'West Berkshire' },
  { key: 'westDevon', label: 'West Devon' },
  { key: 'westDorset', label: 'West Dorset' },
  { key: 'westDunbartonshire', label: 'West Dunbartonshire' },
  { key: 'westLancashire', label: 'West Lancashire' },
  { key: 'westLindsey', label: 'West Lindsey' },
  { key: 'westLothian', label: 'West Lothian' },
  { key: 'westOxfordshire', label: 'West Oxfordshire' },
  { key: 'westSomerset', label: 'West Somerset' },
  { key: 'westSussex', label: 'West Sussex' },
  { key: 'westminster', label: 'Westminster' },
  { key: 'weymouthAndPortland', label: 'Weymouth & Portland' },
  { key: 'wigan', label: 'Wigan' },
  { key: 'wiltshire', label: 'Wiltshire' },
  { key: 'winchester', label: 'Winchester' },
  { key: 'windsorAndMaidenhead', label: 'Windsor and Maidenhead' },
  { key: 'wirral', label: 'Wirral' },
  { key: 'woking', label: 'Woking' },
  { key: 'wokingham', label: 'Wokingham' },
  { key: 'wolverhampton', label: 'Wolverhampton' },
  { key: 'worcester', label: 'Worcester' },
  { key: 'worcestershire', label: 'Worcestershire' },
  { key: 'worthing', label: 'Worthing' },
  { key: 'wrexham', label: 'Wrexham' },
  { key: 'wychavon', label: 'Wychavon' },
  { key: 'wycombe', label: 'Wycombe' },
  { key: 'wyre', label: 'Wyre' },
  { key: 'wyreForest', label: 'Wyre Forest' },
  { key: 'york', label: 'York' },
  { key: 'yorkshireDalesNationalPark', label: 'Yorkshire Dales National Park' },
];

export const projectBudgetOptions = [
  { key: 'fiftyToHundredK', label: '£50-100K' },
  { key: 'hundredToTwoHundredK', label: '£100-200K' },
  { key: 'twoHundredToFourHundredK', label: '£200-400K' },
  { key: 'fourHundredToSevenHundredFiftyK', label: '£400-750K' },
  { key: 'sevenHundredFiftyKToOneM', label: '£750-1.0M' },
  { key: 'oneMToTwoM', label: '£1.0M-2.0M' },
  { key: 'twoMPlus', label: '£2.0M+' },
];

export const houseTypeOptions = [
  {
    key: 'singleStorey',
    label: 'Single Storey',
  },
  {
    key: 'twoStorey',
    label: 'Two Storey',
  },
  {
    key: 'threeStorey',
    label: 'Three Storey',
  },
  {
    key: 'combination',
    label: 'Combination',
  },
];

export const locationAreaOptions = [
  {
    key: 'rural',
    label: 'Rural',
  },
  {
    key: 'suburban',
    label: 'Suburban',
  },
  {
    key: 'urban',
    label: 'Urban',
  },
];

export const floorAreaOptions = [
  { key: 'thirtyToFiftyM2', label: '30-50m2' },
  { key: 'fiftyToSeventyFiveM2', label: '50-75m2' },
  { key: 'seventyFiveToOneHundredM2', label: '75-100m2' },
  { key: 'oneHundredToOneFiftyM2', label: '100-150m2' },
  { key: 'oneFiftyToTwoHundredM2', label: '150-200m2' },
  { key: 'twoHundredM2Plus', label: '200m2+' },
];

export const furtherRequirementsOptions = [
  {
    key: 'projectManager',
    label: 'Project Manager',
  },
  {
    key: 'principalDesigner',
    label: 'Principal Designer',
  },
  {
    key: 'passivhausDesign',
    label: 'Passivhaus Design',
  },
  {
    key: 'sustainableDesign',
    label: 'Sustainable Design',
  },

  {
    key: 'interiorDesign',
    label: 'Interior Design',
  },

  {
    key: 'furtherArchitectDesign',
    label: 'Further Architectural Design',
  },

  {
    key: 'insurance',
    label: 'Insurance',
  },
  {
    key: '10YearStructuralWarranty',
    label: '10 Year Structural Warranty',
  },
  {
    key: 'buildingControl',
    label: 'Building Control',
  },
  {
    key: 'TaxAdvice',
    label: 'Tax Advice',
  },
  {
    key: 'financeAdvice',
    label: 'Finance Advice',
  },
];

export const allOptions = [
  ...buildingSystemOptions,
  ...companyAccrediationsOptions,
  ...structuralWarrantyProviderOptions,
  ...yesNoOptions,
  ...inhouseConsultantsOptions,
  ...houseFacadeOptions,
  ...energySolutionsOptions,
  ...programmeDurationOptions,
  ...geographycalReachOptions,
  ...siteGradientOptions,
  ...planningPermissionOptions,
  ...serviceOptions,
  ...designScopeOptions,
  ...houseDeliveryOptions,
  ...houseCompletionOptions,
  ...plotStageOptions,
  ...siteHistoryOptions,
  ...localPlanningAuthorityOptions,
  ...projectBudgetOptions,
  ...houseTypeOptions,
  ...locationAreaOptions,
  ...floorAreaOptions,
  ...furtherRequirementsOptions,
].reduce((acc, curr) => {
  acc[curr.key] = curr.label;
  return acc;
}, {});

export const removeUndefinedValues = jsonObj => {
  const result = {};

  for (const key in jsonObj) {
    if (jsonObj.hasOwnProperty(key)) {
      if (jsonObj[key] !== undefined) {
        if (typeof jsonObj[key] === 'object' && !Array.isArray(jsonObj[key])) {
          // If the value is an object, recursively remove undefined values
          const cleanedNestedObject = removeUndefinedValues(jsonObj[key]);

          // If the object becomes empty after removing undefined values, don't include it
          if (Object.keys(cleanedNestedObject).length !== 0) {
            result[key] = cleanedNestedObject;
          }
        } else {
          result[key] = jsonObj[key];
        }
      }
    }
  }

  return result;
};
